import { useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import { Close } from "@mui/icons-material";

export const GoalForm = ({ empList, finData, setFinData }) => {
    const tempList ={};
    Object.values(empList).map((vaal,key)=> {
        tempList[vaal.emp_uuid] = {"employee_name": vaal.employee_name};
    })

    // console.log(tempList);

    const handleChange = (event, value) => {
        setFinData({ ...finData, emp_id: value });
    };

    const handleInChange = (e, ind) => {
        const updatedTaskInfo = finData.goals;
        updatedTaskInfo[ind] = {"goal":e.target.value,"key_milestone": []};
        setFinData({ ...finData, goals: updatedTaskInfo });
    }

    const addEventCard = () => {
        const updatedPoc = [...finData.goals];
        updatedPoc.push({
            "goal": "",
            "key_milestone": []
        });
        setFinData((prevState) => ({
            ...prevState,
            goals: updatedPoc,
        }));
    };

    const handleClear = (index) => {
        const updatedPoc = [...finData.goals];
        updatedPoc.splice(index, 1);
        setFinData((prevState) => ({
            ...prevState,
            goals: updatedPoc,
        }));
    }

    return (
        <Box>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    sx={{'.MuiInputBase-input': {
                        height: '19px !important'
                    }
                }}
                    id={`employee_id`}
                    value={finData.emp_id}
                    onChange={(e, val) => handleChange(e, val)}
                    options={Object.keys(tempList)}
                    getOptionLabel={(option) => (tempList[option] ? tempList[option].employee_name : '')}
                    getOptionSelected={(option, value) => tempList[option].employee_name === value}
                    renderInput={(params) => <TextField {...params} label="Select Assignee" variant="outlined" />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <FormControlLabel
                                control={<Checkbox checked={selected} />}
                                label={tempList[option].employee_name}
                            />
                        </li>
                    )}
                />
            </Grid>
            
            {
            finData.goals.length<6?
            <Grid item xs={12} mt={3}>
                <Button onClick={addEventCard} variant="outlined" sx={{width: '100%'}} endIcon={<AddIcon fontSize="7px" />}>Add New Goal</Button>
            </Grid>
            :<></>
            }

            <Grid mt={0} container spacing={2}>
            {finData.goals.map((val, index) => (
                        <Grid item xs={12}>
                            <TextField
                                value={val.goal ?? ""}
                                id={`goal_${index}`}
                                label={`Goal ${index+1}`}
                                fullWidth
                                onChange={(e) => handleInChange(e, index, "event_title")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear(index)}
                                                edge="end"
                                            >
                                                <Close color="error" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                    </Grid>
            ))}
            </Grid>
        </Box>
    )
}
